@font-face {
  font-family: rubik;
  src: url(Rubik-VariableFont_wght.ttf);
}

body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: rubik, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--text-color);
}

#container {
  display: flex;
  align-self: center;
  width: 70vw;
  min-width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  position: relative;
}

#roller {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  min-width: 350px;
}

button {
  height: 10vh;
}

p {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 1rem;
}

#roll-btn-emoji {
  font-size: 3rem;
}

#roll-btn-content {
  font-weight: bold;
  color: var(--text-color);
}

#roll-btn {
  padding: 10px;
  margin-bottom: 1.25rem;
  display: grid;
  height: 100px;
  width: 300px;
  background-color: var(--button-background-color);
  border-radius: 10px;
  border: solid var(--text-color) 5px;
}

.spinning {
  animation: dice-spin 1.5s ease-in-out 0s;
}

.rainbowing {
  animation: rainbow 1.5s ease-in-out 0s;
}

@keyframes dice-spin {
  0% {
    transform: none;
  }
  100% {
    transform: rotateZ(1080deg);
  }
}

@keyframes rainbow {
  0% {
    filter: hue-rotate(0);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.ck-wrap {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
}

select {
  margin-right: 10px;
}

input[type="checkbox"] {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

#preferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  min-width: 350px;
}

#preferences ul {
  padding: 0;
}

#preferences li {
  list-style-type: none;
}

.preference-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
}

.preference-question-mark-container {
  margin-left: 0.5rem;
  padding-left: 0.425rem;
  padding-right: 0.425rem;
  border: solid #aaa 1px;
  border-radius: 50px;
}

.preference-question-mark {
  opacity: 0.5;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  width: 150px;
  margin-left: -75px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Firefox touch device fix */
input[disabled] {pointer-events:none}

:root {
  --background-color: #f2f2f2;
  --text-color: #2f3034;
  --button-background-color: #ffcada;
}

[data-theme="dark"] {
  --background-color: #2f3034;
  --text-color: #f2f2f2;
  --button-background-color: #6c243a;
}
